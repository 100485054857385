import { Fragment } from 'react';
import { ToastContainer } from 'react-toastify';
import { PopupRenderer } from '@components/main';
import { RootRouter } from './routes/routers';

const App = () => (
  <Fragment>
    <RootRouter />
    <ToastContainer />
    <PopupRenderer />
  </Fragment>
);

export default App;
